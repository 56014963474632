// i18next-extract-mark-ns-start shopify-payments-alternative

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground as _IndexBackground} from 'components/landings/IndexBackground';
import {
  ListRow as _ListRow,
  PlansContainer,
  PricingSection,
  SmPlanLabel
} from 'components/pricing/PricingSection';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import shopify_monei from 'images/shopify_monei.svg';
import logo from 'images/shopify_payment_gateway_logo.svg';
import React, {useEffect, useState} from 'react';
import {HiCheck, HiOutlineX} from 'react-icons/all';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {TrustedBy} from '../components/TrustedBy';
import Bold from '../components/Bold';
import {Button} from '../components/Button';
import dashboard_screenshot_en from '../images/dashboard_screenshot_en.png';
import dashboard_screenshot_es from '../images/dashboard_screenshot_es.png';
import bill from '../images/bill.svg';
import person from '../images/person.svg';
import shield from '../images/shield.svg';
import {StyledDiv, StyledImg, StyledP} from '../components/StyledSx';
import {SupportLink} from '../components/links/Support';
import {FaqsSection} from '../components/FaqsSection';
import {PrismicPage} from '../components/links/PrismicPage';
import mediamarkt from '../images/mediamarkt_logo.svg';
import telefonica_logo from 'images/telefonica_logo.svg';
import mango from '../images/mango-logo.png';
import marriot from '../images/marriott_Logo.png';
import scalpers from '../images/scalpers_logo.svg';
import trendgraph from '../images/trendgraph.svg';
import {Client} from '../components/Clients';
import {ScreenSizes} from '../../types/responsive';
import {IndexContent} from '../components/landings/IndexContent';
import {Flex} from '../components/Flex';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from '../components/landings/GradientBox';
import rocket from '../images/rocket.svg';
import {JSX} from 'preact';
import visa_logo from '@wp-pay/logos/dist/methods/visa/method-visa-640x360.svg';
import paypal_logo from '@wp-pay/logos/dist/methods/paypal/method-paypal-640x360.svg';
import mastercard_logo from '@wp-pay/logos/dist/methods/mastercard/method-mastercard-640x360.svg';
import googlepay_logo from '@wp-pay/logos/dist/methods/google-pay/method-google-pay-640x360.svg';
import applepay_logo from '@wp-pay/logos/dist/methods/apple-pay/method-apple-pay-640x360.svg';
import bizum_logo from '../images/bizum_payment_gateway.svg';
import mb_way_logo from '@wp-pay/logos/dist/methods/mb-way/method-mb-way-640x360.svg';
import sepa_logo from 'images/sepa-logo.svg';
import jcb_logo from '@wp-pay/logos/dist/cards/jcb/card-jcb-logo-_x80.svg';
import dinners_logo from '@wp-pay/logos/dist/cards/diners-club/card-diners-club-logo-_x80.svg';
import discover_logo from '@wp-pay/logos/dist/cards/discover/card-discover-logo-_x80.svg';
import {GoogleReviews, Place} from '../components/GoogleReviews';
import Italic from '../components/Italic';
import {Slider} from '../components/Slider';

const IndexBackground = styled(_IndexBackground)`
  &:before {
    transform: rotate(-64deg) matrix(1, 0, 0, 1, 720, 0);
    height: 2700px;
    width: 2080px;
    left: 75%;
    @media (max-width: ${ScreenSizes.lg}) {
      display: none;
    }
  }
`;

const PlanHeader = styled.div`
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 10px 43%;

  @media (max-width: ${ScreenSizes.sm}) {
    padding: 0;
  }
`;

const ListRow = styled(_ListRow)`
  justify-content: space-between;
  padding: 10px 30px;

  @media (max-width: ${ScreenSizes.md}) {
    padding: 10px 13px;
    text-align: start;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    font-size: 0.85em;
    gap: 15px;
    > div {
      flex: 3;
    }

    > svg,
    > p {
      flex: 2;
      @media (max-width: ${ScreenSizes.md}) {
        line-height: 1.1rem;
      }
    }
  }
`;

const Plan = styled(StyledDiv)`
  margin: -30px 0 50px;
  position: relative;
  z-index: 1;
  width: 320px;
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: ${ScreenSizes.lg}) {
    width: 290px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    width: 230px;
  }

  @media (max-width: ${ScreenSizes.sm}) {
    width: 65%;
    margin: 50px 0 0;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    width: 80%;
    margin: 50px 0 0;
  }
`;

const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 150px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 45%;
  text-align: start;

  li:after {
    display: block;
    content: '';
    width: 20%;
    @media (max-width: ${ScreenSizes.md}) {
      width: 23%;
    }
  }

  @media (max-width: ${ScreenSizes.md}) {
    font-size: 0.85em;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${ListRow} {
    justify-content: center;
  }
`;

export const PlansList = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 8px;
    justify-content: initial;
    overflow-x: scroll;
  }
`;

const ThreeLineListRow = styled.p`
  @media (max-width: 780px) {
    height: 68px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const YesIcon = styled(HiCheck)`
  font-size: 32px;
  color: green;
`;

const NoIcon = styled(HiOutlineX)`
  font-size: 32px;
  color: red;
`;

const DashboardScreenshot = styled.img`
  width: 550px;
  margin-bottom: 0;
  display: block;
  border-radius: 6px;
  box-shadow: 0px 64px 64px rgba(61, 88, 128, 0.05), 0 32px 32px rgba(61, 88, 128, 0.05),
    0 16px 16px rgba(61, 88, 128, 0.05), 0 8px 8px rgba(61, 88, 128, 0.05),
    0 4px 4px rgba(61, 88, 128, 0.05), 0 2px 2px rgba(61, 88, 128, 0.05);
  @media (max-width: ${ScreenSizes.lg}) {
    width: 350px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const clients: Client[] = [
  {
    logo: mediamarkt,
    url: {
      es: 'https://www.mediamarkt.es/',
      de: 'https://www.mediamarkt.de/',
      pt: 'https://www.mediamarkt.pt/'
    },
    alt: 'Mediamarkt payment gateway',
    width: 150,
    scale: 1
  },
  {
    logo: telefonica_logo,
    url: {
      es: 'https://www.telefonica.es/es/',
      en: 'https://www.telefonica.com/en/'
    },
    alt: 'Telefonica',
    width: 150,
    scale: 1
  },
  {
    logo: mango,
    url: {
      es: 'https://shop.mango.com/es',
      fr: 'https://shop.mango.com/fr',
      de: 'https://shop.mango.com/de',
      en: 'https://shop.mango.com/gb',
      pt: 'https://shop.mango.com/pt',
      it: 'https://shop.mango.com/it'
    },
    alt: 'Mango payment gateway',
    width: 150,
    scale: 1
  },
  {
    logo: marriot,
    url: {
      es: 'https://www.espanol.marriott.com',
      fr: 'https://www.marriott.fr',
      pt: 'https://www.marriott.com.br',
      de: 'https://www.marriott.de',
      it: 'https://www.marriott.it',
      en: 'https://www.marriott.com'
    },
    alt: 'Marriot payment gateway',
    width: 150,
    scale: 0.9
  },
  {
    logo: scalpers,
    url: {
      es: 'https://www.scalpers.com/',
      pt: 'https://pt.scalperscompany.com/',
      fr: 'https://fr.scalperscompany.com/',
      de: 'https://de.scalperscompany.com/',
      en: 'https://en.eu.scalperscompany.com/'
    },
    alt: 'Scalpers payment gateway',
    width: 150,
    scale: 1
  }
];

const paymentMethods: JSX.Element[] = [
  <StyledImg src={visa_logo} alt="Visa" title="Visa" />,
  <StyledImg src={paypal_logo} alt="PayPal" title="PayPal" />,
  <StyledImg src={mastercard_logo} alt="Mastercard" title="Mastercard" />,
  <StyledImg src={googlepay_logo} alt="Google Pay" title="Google Pay" />,
  <StyledImg src={applepay_logo} alt="Apple Pay" title="Apple Pay" />,
  <StyledImg src={bizum_logo} alt="Bizum" title="Bizum" />,
  <StyledImg src={mb_way_logo} alt="MB Way" title="MB Way" />,
  <StyledImg src={sepa_logo} alt="SEPA" title="SEPA" sx={{width: '80%'}} />,
  <StyledImg src={jcb_logo} alt="JCB" title="JCB" />,
  <StyledImg
    src={dinners_logo}
    alt="Dinners Club"
    title="Dinners Club"
    sx={{paddingRight: '20px'}}
  />,
  <StyledImg
    src={discover_logo}
    alt="Discover payment gateway"
    title="Discover payment gateway"
    sx={{paddingLeft: '20px'}}
  />
];

const Dots = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: -20px auto;
  @media (min-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const Dot = styled.div<{active: boolean}>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #d8d8d8;
  ${(props) => props.active && 'background-color: #00c1b1;'}
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin: 100px 0;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const ShopifyPaymentsAlternative: React.FC<PageProps> = (props) => {
  const {t, navigate, language} = useI18next();
  const dashboardScreenshot = language === 'es' ? dashboard_screenshot_es : dashboard_screenshot_en;
  const [activePlan, setActivePlan] = useState<'monei' | 'shopify'>('monei');
  const faqs = [
    {
      header: t('Why do I need an alternative to Shopify Payments?'),
      text: t(
        'Shopify Payments are a good solution if you only want to accept a few payment methods, but in order to reach more customers and increase e-commerce conversions, it’s crucial to accept alternative and local payment methods. Rather than setting up multiple integrations to do this, use a Shopify Payments alternative like MONEI that lets you configure a wide range of payment methods from a single platform while saving money on transaction fees.'
      ),
      content: (
        <Trans>
          Shopify Payments are a good solution if you only want to accept a few payment methods, but
          in order to reach more customers and{' '}
          <BlogLink slug="ecommerce-conversion-rate-tips-to-help-you-sell-more">
            increase e-commerce conversions
          </BlogLink>
          , it’s crucial to accept{' '}
          <BlogLink slug="alternative-payment-methods">alternative</BlogLink> and{' '}
          <BlogLink slug="local-payment-methods">local payment methods</BlogLink>. Rather than
          setting up multiple integrations to do this, use a Shopify Payments alternative like{' '}
          <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/" external>
            MONEI
          </AnchorLink>{' '}
          that lets you configure a wide range of payment methods from a single platform while
          saving money on transaction fees.
        </Trans>
      )
    },
    {
      header: t('Is it easy to switch to MONEI as an alternative to Shopify Payments?'),
      text: t(
        'Yes. Use our Shopify integration to simply install and configure MONEI in your Shopify store.'
      ),
      content: (
        <Trans>
          Yes. Use our{' '}
          <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/" external>
            Shopify integration
          </AnchorLink>{' '}
          to simply install and configure MONEI in your Shopify store.
        </Trans>
      )
    },
    {
      header: t('What countries does MONEI support?'),
      text: t(
        'Currently, MONEI supports merchants in Spain and Andorra. MONEI connects you to a wide range of local and alternative payment methods so you can accept cross-border payments and grow your business internationally. Our global expansion is happening fast (soon merchants in Portugal and France will be able to integrate with MONEI). Subscribe to our newsletter to get notified when MONEI is available in your country.'
      ),
      content: (
        <Trans>
          Currently, <SupportLink slug="/articles/360017953338">MONEI supports</SupportLink>{' '}
          merchants in <InternalPageLink slug="payment-gateway-spain">Spain</InternalPageLink> and{' '}
          <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink>. MONEI
          connects you to a wide range of local and alternative payment methods so you can accept{' '}
          <BlogLink slug="cross-border-payments">cross-border payments</BlogLink> and grow your
          business internationally. Our global expansion is happening fast (soon merchants in
          Portugal and France will be able to integrate with MONEI).{' '}
          <InternalPageLink slug="subscribe">Subscribe to our newsletter</InternalPageLink> to get
          notified when MONEI is available in your country.
        </Trans>
      )
    },
    {
      header: t(
        'Does using an alternative to Shopify Payments like MONEI affect the checkout process?'
      ),
      text: t(
        'With MONEI, you will improve the checkout experience and increase conversions by accepting more payment methods. And you can customize your prebuilt checkout page with your logo, branding, and company URL so customers won’t be redirected to a third-party domain.'
      ),
      content: (
        <Trans>
          With MONEI, you will improve the checkout experience and increase conversions by accepting
          more payment methods. And you can{' '}
          <SupportLink slug="/articles/360017814717">customize</SupportLink> your{' '}
          <AnchorLink
            href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/"
            external>
            prebuilt checkout page
          </AnchorLink>{' '}
          with your logo, branding, and company URL so customers won’t be redirected to a
          third-party domain.
        </Trans>
      )
    },
    {
      header: t('Can I use MONEI and Shopify Payments at the same time?'),
      text: t(
        'Technically, yes. But it’s not necessary because MONEI supports all the same payment methods (cards, Apple Pay, Google Pay) and more like Bizum, Click to Pay, PayPal, MB WAY, and Bancontact (view all here). MONEI is all you need.'
      ),
      content: (
        <Trans>
          Technically, yes. But it’s not necessary because MONEI supports all the same payment
          methods (<InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>) and
          more like <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>, and{' '}
          <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink> (
          <InternalPageLink slug="payment-methods">view all here</InternalPageLink>). MONEI is all
          you need.
        </Trans>
      )
    },
    {
      header: t(
        'How does using MONEI as an alternative to Shopify Payments affect my transaction fees?'
      ),
      text: t(
        'You’ll save money on transaction fees thanks to our dynamic billing system. As your sales increase, your transaction fees will decrease in real time.'
      ),
      content: (
        <Trans>
          You’ll save money on transaction fees thanks to our{' '}
          <SupportLink slug="/articles/360017954318">dynamic billing system</SupportLink>. As your
          sales increase, your transaction fees will decrease in real time.
        </Trans>
      )
    },
    {
      header: t('Is MONEI a secure Shopify Payments alternative?'),
      text: t(
        'MONEI is PSD2 and PCI DSS Level 1 compliant, supports 3D Secure checkout, and uses tokenization technology. Business and customer payment information is always safe with us.'
      ),
      content: (
        <Trans>
          MONEI is <BlogLink slug="psd2">PSD2</BlogLink> and{' '}
          <InternalPageLink slug="pci-dss">PCI DSS</InternalPageLink> Level 1 compliant, supports{' '}
          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">3D Secure</BlogLink>{' '}
          checkout, and uses{' '}
          <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
            tokenization
          </BlogLink>{' '}
          technology. Business and customer payment information is always safe with us.
        </Trans>
      )
    },
    {
      header: t('Does choosing MONEI as an alternative to Shopify Payments affect customer data?'),
      text: t(
        'With Shopify Payments, you can only see the status of each order, but you can’t see payment/transaction details like you can in your MONEI Dashboard. Detailed payment data makes it easier to identify payment issues and fix them before you lose sales.'
      ),
      content: (
        <Trans>
          With Shopify Payments, you can only see the status of each order, but you can’t see
          payment/transaction details like you can in your{' '}
          <AnchorLink href="https://dashboard.monei.com/" external>
            MONEI Dashboard
          </AnchorLink>
          . Detailed payment data makes it easier to identify payment issues and fix them before you
          lose sales.
        </Trans>
      )
    },
    {
      header: t('Can MONEI integrate with my existing e-commerce setup?'),
      text: t('Absolutely. Install MONEI via the Shopify App Store and follow these instructions.'),
      content: (
        <Trans>
          Absolutely. Install MONEI{' '}
          <AnchorLink href="https://apps.shopify.com/monei-payments" external>
            via the Shopify App Store
          </AnchorLink>{' '}
          and follow these{' '}
          <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/" external>
            instructions
          </AnchorLink>
          .
        </Trans>
      )
    },
    {
      header: t(
        'How does customer support compare between Shopify Payments and an alternate like MONEI?'
      ),
      text: t(
        'We offer personalized support to all our clients. Depending on your needs, we can provide a dedicated Slack channel to speed up the process. Our team of payment technology experts can also make suggestions to help improve your conversion rates.'
      ),
      content: (
        <Trans>
          We offer personalized{' '}
          <SupportLink slug="https://support.monei.com/hc/">support</SupportLink> to all our
          clients. Depending on your needs, we can provide a dedicated Slack channel to speed up the
          process. Our team of payment technology experts can also make suggestions to help improve
          your conversion rates.
        </Trans>
      )
    },
    {
      header: t(
        'Can I still manage my orders in Shopify if I use a Shopify Payments alternative like MONEI?'
      ),
      text: t(
        'Yes, you can still manage your orders in Shopify when you use MONEI as your payment gateway.'
      ),
      content: (
        <Trans>
          Yes, you can still manage your orders in Shopify when you use MONEI as your{' '}
          <InternalPageLink slug="shopify-payment-gateway">payment gateway</InternalPageLink>.
        </Trans>
      )
    },
    {
      header: t("Does MONEI offer the same level of integration with Shopify's POS system?"),
      text: t(
        'MONEI’s Shopify POS integration is coming soon (subscribe to get notified). Or use MONEI Pay to accept contactless in-person payments from your smartphone.'
      ),
      content: (
        <Trans>
          MONEI’s Shopify POS integration is coming soon (
          <InternalPageLink slug="subscribe">subscribe</InternalPageLink> to get notified). Or use{' '}
          <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> to accept contactless
          in-person payments from your smartphone.
        </Trans>
      )
    },
    {
      header: t('How does MONEI compare to other payment solutions aside from Shopify Payments?'),
      text: t('Compare MONEI to more payment service providers here.'),
      content: (
        <Trans>
          Compare MONEI to more payment service providers{' '}
          <PrismicPage slug="compare-payment-gateways">here</PrismicPage>.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  useEffect(() => {
    const plansList = document.getElementById('plans-list') as HTMLElement;
    const shopifyPlan = document.getElementById('shopify-plan') as HTMLElement;
    plansList.addEventListener('scroll', () => {
      const shopifyPlanRects = shopifyPlan.getBoundingClientRect();
      if (shopifyPlanRects.x < window.innerWidth / 2) {
        setActivePlan('shopify');
      } else {
        setActivePlan('monei');
      }
    });
  }, []);

  return (
    <>
      <SEO
        title={t('Shopify Payments Alternative')}
        description={t(
          'Looking for the best Shopify Payments alternative? Accept Bizum and more payment methods from a single platform. Pay less on transaction fees. Join MONEI now ››'
        )}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <Section sx={{paddingTop: '60px', paddingBottom: '0'}}>
            <IndexContent sx={{maxWidth: 'unset'}}>
              <SectionHeader underline tagName="h1">
                <Trans>Shopify Payments alternative</Trans>
              </SectionHeader>
              <Trans parent="p">
                If you’re looking for a <Bold>Shopify Payments alternative</Bold>, you’ve come to
                the right place.
              </Trans>
              <Trans parent="p">
                MONEI lets you accept all the same payment options <Italic>and</Italic>{' '}
                <Bold>alternative and local payment methods from a single platform</Bold>.
              </Trans>
              <Trans parent="p">
                Sign up now to <Bold>increase sales</Bold> and <Bold>save money</Bold> with one
                integration.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <StyledDiv>
              <IndexImage
                top="0"
                left="0"
                src={shopify_monei}
                alt="PayPal Alternative"
                title="PayPal Alternative"
                hideLtMd
                sx={{position: 'relative', width: 'auto'}}
              />
            </StyledDiv>
          </Section>
          <TrustedBy clientList={clients} />
        </Content>
      </IndexBackground>

      <Content>
        <Section centered column sx={{textAlign: 'center', maxWidth: '1280px'}}>
          <SectionHeader underline>
            <Trans>Why MONEI is the best Shopify Payments alternative</Trans>
          </SectionHeader>
          <Slider
            items={paymentMethods}
            options={{
              perView: {all: 5, sm: 3, xs: 2},
              autoplay: true,
              loop: true,
              spacing: {all: 40, md: 20, sm: 10}
            }}
            sx={{overflow: 'unset'}}
            slideHeight={150}
          />
          <Flex
            direction="column"
            gap={15}
            sx={{maxWidth: '1024px', marginTop: '50px'}}
            alignItems="center">
            <SectionHeader>
              <Trans>Accept Bizum and more payment methods with a single integration</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Shopify Payments, you can only accept cards, Apple Pay, and Google Pay. You need
              multiple payment gateway integrations to accept local and alternative payment methods.
            </Trans>
            <Trans parent="p">
              With MONEI as your alternative, you can <Bold>stay competitive</Bold> and{' '}
              <Bold>boost conversions</Bold> by accepting{' '}
              <InternalPageLink slug="bizum-for-shopify">Bizum</InternalPageLink>, the second{' '}
              <Bold>most used</Bold> payment method in Spain.
            </Trans>
            <Trans parent="p">
              And with the <Bold>same integration</Bold>, you can add{' '}
              <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
              <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
              <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>, and
              more{' '}
              <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
              like{' '}
              <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>,{' '}
              <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>, and{' '}
              <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink> to
              your e-commerce checkout.
            </Trans>
            <Button
              variant="dark"
              onClick={() => navigate('/payment-methods/')}
              style={{marginTop: 20}}>
              <Trans>View All Payment Methods</Trans>
            </Button>
          </Flex>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section sx={{alignItems: 'center', gap: '30px', paddingTop: {lg: '8%', sm: '20%'}}}>
            <DashboardScreenshot
              src={dashboardScreenshot}
              alt="Recurring payments phone"
              title="Recurring payments phone"
            />
            <div>
              <SectionHeader>
                <Trans>Take control of your business with real-time payment analytics</Trans>
              </SectionHeader>
              <Trans parent="p">
                With Shopify Payments, you can look up order history and status, but you can’t view
                immediate payment data.
              </Trans>
              <Trans parent="p">
                From your{' '}
                <AnchorLink href="https://dashboard.monei.com/?action=signUp" external>
                  MONEI Dashboard
                </AnchorLink>
                , you can <Bold>review and analyze live, detailed online payment data</Bold> to
                better understand the causes of failed transactions. With the right information at
                your fingertips, you can <Bold>optimize your payments</Bold> and{' '}
                <Bold>increase conversions</Bold>.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Get MONEI</Trans>
              </SignUpButton>
            </div>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section sx={{alignItems: 'center'}}>
          <StyledDiv>
            <SectionHeader underline>
              <Trans>Pay less on transaction and integration fees</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Shopify Payments, you pay set transaction fees regardless of your sales volume,
              which eats into your profits.
            </Trans>
            <Trans parent="p">
              MONEI grows with your business. The <Bold>more you sell</Bold>, the{' '}
              <Bold>less you’ll spend</Bold> on transaction{' '}
              <SupportLink slug="/articles/360017954318">fees</SupportLink>.
            </Trans>
            <Trans parent="p">
              Plus, by <Bold>managing your entire payment stack from a single platform</Bold>,
              you’ll <Bold>save time and money</Bold> on multiple{' '}
              <AnchorLink
                href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/"
                external>
                integrations
              </AnchorLink>
              .
            </Trans>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={bill} alt="Pay less" title="Pay less" sx={{width: '30%'}} />
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={person} alt="Get support" title="Get support" sx={{width: '40%'}} />
          </StyledDiv>
          <StyledDiv>
            <SectionHeader>
              <Trans>Get support from payments experts</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Shopify Payments, you’ll get support when you need it, but you’ll likely speak
              with a CMS expert that has no payment technology experience.
            </Trans>
            <Trans parent="p">
              <Bold>MONEI’s payment technology experts</Bold> are here to help you every step of the
              way.
            </Trans>
            <Trans parent="p">
              From account setup and integration to troubleshooting and knowing which payment
              methods to accept, <Bold>get personalized</Bold>{' '}
              <SupportLink slug="/requests/new">support</SupportLink>.
            </Trans>
            <SignUpButton variant="light">
              <Trans>Get MONEI</Trans>
            </SignUpButton>
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section>
          <StyledDiv>
            <SectionHeader>
              <Trans>Payment security for merchants and customers</Trans>
            </SectionHeader>
            <Trans parent="p">
              Just like Shopify Payments, MONEI is{' '}
              <InternalPageLink slug="pci-dss">PCI compliant</InternalPageLink>, supports{' '}
              <Bold>3D Secure checkouts</Bold>, and uses <Bold>tokenization</Bold> to encrypt
              sensitive{' '}
              <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/" external>
                payment information
              </AnchorLink>
              .
            </Trans>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={shield} alt="Get support" title="Get support" sx={{width: '25%'}} />
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section centered sx={{paddingTop: {all: '3%', sm: '12%'}, gap: '20px'}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={trendgraph} alt="Get support" title="Get support" sx={{width: '40%'}} />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Increase your transaction acceptance rate</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Shopify Payments, you don’t have the option to improve your transaction
              acceptance rate by configuring more payment processors in your account.
            </Trans>
            <Trans parent="p">
              With MONEI’s{' '}
              <InternalPageLink slug="features/payments-orchestration">
                payments orchestration platform (POP)
              </InternalPageLink>
              , you can <Bold>configure multiple payment processors in your account</Bold> and set
              up routing rules to send payments to the best-performing processor.
            </Trans>
            <Trans parent="p">
              The result? <Bold>Fewer failed payments</Bold> and <Bold>higher conversions</Bold>.
              Upgrade to <InternalPageLink slug="pricing">MONEI PLUS</InternalPageLink> to unlock
              payments orchestration.
            </Trans>
            <div></div>
            <ContactSalesButton variant="dark" />
          </div>
        </Section>
      </Content>

      {/* i18next-extract-mark-ns-start alternatives */}
      <Content>
        <Section column sx={{textAlign: 'center', gap: '30px'}}>
          <SectionHeader underline>
            <Trans>Compare MONEI to Shopify Payments</Trans>
          </SectionHeader>
          <PricingSection>
            <Dots>
              <Dot active={activePlan === 'monei'} />
              <Dot active={activePlan === 'shopify'} />
            </Dots>
            <PlansTable>
              <PlanLabels>
                <Trans parent={ListRow}>
                  <SupportLink slug="/articles/360017814717">Customizable checkout</SupportLink>{' '}
                  (branding and domain)
                </Trans>
                <ListRow sx={{height: '75px'}}>
                  <Trans>
                    <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink>
                  </Trans>
                </ListRow>
                <Trans parent={ListRow}>
                  Local payment methods (
                  <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>,
                  and more)
                </Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>{' '}
                  (one-click payments)
                </Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="payment-methods/credit-cards">Cards</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>One-day payout</Trans>
                <Trans parent={ListRow}>Real-time payments dashboard and analytics</Trans>
                <Trans parent={ListRow}>Abandoned orders tracking</Trans>
                <Trans parent={ListRow}>Expert payments support</Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="features/payments-orchestration">
                    Payments orchestration
                  </InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>
                  Send payment requests and receipts via{' '}
                  <InternalPageLink slug="features/whatsapp-payments">WhatsApp</InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>Accept in-person payments</Trans>{' '}
                <Trans parent={ListRow}>
                  <InternalPageLink slug="pci-dss">PCI Compliance</InternalPageLink> and{' '}
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3D Secure
                  </BlogLink>
                  checkout
                </Trans>
              </PlanLabels>
              <PlansContainer>
                <PlansList id="plans-list">
                  <Plan id="monei-plan">
                    <PlanHeader>
                      <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017814717">
                            Customizable checkout
                          </SupportLink>{' '}
                          (branding and domain)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow sx={{height: '75px'}}>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink>
                        </Trans>
                        <StyledP
                          sx={{
                            fontSize: {lg: '0.85em'},
                            lineHeight: {lg: '1.2em'},
                            textAlign: 'center'
                          }}>
                          <Trans>Dynamic rate — pay less as you sell more</Trans>
                        </StyledP>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Local payment methods (
                          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
                          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>,{' '}
                          <InternalPageLink slug="payment-methods/bancontact">
                            Bancontact
                          </InternalPageLink>
                          , and more)
                        </Trans>
                        <ThreeLineListRow>
                          <YesIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/click-to-pay">
                            Click to Pay
                          </InternalPageLink>{' '}
                          (one-click payments)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/credit-cards">
                            Cards
                          </InternalPageLink>
                          ,{' '}
                          <InternalPageLink slug="payment-methods/google-pay">
                            Google Pay
                          </InternalPageLink>
                          , and{' '}
                          <InternalPageLink slug="payment-methods/apple-pay">
                            Apple Pay
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>One-day payout</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Real-time payments dashboard and analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Abandoned orders tracking</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Expert payments support</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="features/payments-orchestration">
                            Payments orchestration
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Send payment requests and receipts via{' '}
                          <InternalPageLink slug="features/whatsapp-payments">
                            WhatsApp
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Accept in-person payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="pci-dss">PCI Compliance</InternalPageLink> and{' '}
                          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                            3D Secure
                          </BlogLink>{' '}
                          checkout
                        </Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                  <Plan id="shopify-plan">
                    <PlanHeader>
                      <img src={logo} alt="Shopify Payments" />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017814717">
                            Customizable checkout
                          </SupportLink>{' '}
                          (branding and domain)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow sx={{height: '75px'}}>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink>
                        </Trans>
                        <StyledP
                          sx={{
                            fontSize: {lg: '0.85em'},
                            lineHeight: {lg: '1.2em'},
                            textAlign: 'center'
                          }}>
                          <Trans>Fixed rate regardless of sales volume</Trans>
                        </StyledP>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Local payment methods (
                          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
                          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>,{' '}
                          <InternalPageLink slug="payment-methods/bancontact">
                            Bancontact
                          </InternalPageLink>
                          , and more)
                        </Trans>
                        <ThreeLineListRow>
                          <NoIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/click-to-pay">
                            Click to Pay
                          </InternalPageLink>{' '}
                          (one-click payments)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/credit-cards">
                            Cards
                          </InternalPageLink>
                          ,{' '}
                          <InternalPageLink slug="payment-methods/google-pay">
                            Google Pay
                          </InternalPageLink>
                          , and{' '}
                          <InternalPageLink slug="payment-methods/apple-pay">
                            Apple Pay
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>One-day payout</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Real-time payments dashboard and analytics
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Abandoned orders tracking</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Expert payments support</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="features/payments-orchestration">
                            Payments orchestration
                          </InternalPageLink>
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Send payment requests and receipts via{' '}
                          <InternalPageLink slug="features/whatsapp-payments">
                            WhatsApp
                          </InternalPageLink>
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Accept in-person payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="pci-dss">PCI Compliance</InternalPageLink> and{' '}
                          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                            3D Secure
                          </BlogLink>{' '}
                          checkout
                        </Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                </PlansList>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </Section>
      </Content>

      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '60px'}}
          contentStyle={{maxWidth: {all: '600px', md: '100%'}}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Grow your business faster with the best Shopify Payments alternative</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Sign up for MONEI today to get all your payment needs from a single platform.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '-60px',
              right: '0',
              width: '33%'
            }}
            src={rocket}
          />
        </GradientBox>
      </Content>

      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Shopify Payments alternative FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default ShopifyPaymentsAlternative;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {in: ["common", "shopify-payments-alternative", "alternatives"]}
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
